import { ProductDataType } from "../types/product";
import { COLORS } from "./colors";
import { IMAGES } from "./require";

export const PantherL1Data: ProductDataType[] = [
    {
        id: 1,
        key: "case",
        type: "products.lion01.type",
        series: "Iphone 13, 14, 15 pro, pro max series",
        name: "Panther L1",
        description: "products.lion01.description",
        info: "Lorem",
        price: 10,
        discount: "$15",
        discountP: "-33%",
        colors: [COLORS.black, COLORS.darkBrown, COLORS.darkBlue],
        models: [
            "13 pro",
            "13 pro max",
            "14 pro",
            "14 pro max",
            "15 pro",
            "15 pro max",
        ],
        mediaWithInfo: [
            IMAGES.lion1.g,
            IMAGES.lion1.c,
            IMAGES.lion1.h,
            IMAGES.lion1.a,
            IMAGES.lion1.d,
        ],
        media: [
            {
                id: 1,
                image: IMAGES.lion1.pic1,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.lion1.pic2,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.lion1.pic3,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.lion1.pic4,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 5,
                image: IMAGES.lion1.pic5,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 6,
                image: IMAGES.lion1.pic6,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 7,
                image: IMAGES.lion1.pic7,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 8,
                image: IMAGES.lion1.pic8,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 9,
                image: IMAGES.lion1.pic9,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 10,
                image: IMAGES.lion1.pic10,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 11,
                image: IMAGES.lion1.pic11,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 12,
                image: IMAGES.lion1.pic12,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
        ],
    },
    {
        id: 1,
        key: "case",
        type: "products.lion01.type",
        series: "Iphone 13, 14, 15 pro, pro max series",
        name: "Panther L1",
        description: "products.lion01.description",
        info: "Lorem",
        price: 10,
        discount: "$15",
        discountP: "-33%",
        colors: [COLORS.black, COLORS.darkBrown, COLORS.darkBlue],
        models: [
            "13 pro",
            "13 pro max",
            "14 pro",
            "14 pro max",
            "15 pro",
            "15 pro max",
        ],
        mediaWithInfo: [
            IMAGES.lion1.g,
            IMAGES.lion1.c,
            IMAGES.lion1.h,
            IMAGES.lion1.a,
            IMAGES.lion1.d,
        ],
        media: [
            {
                id: 1,
                image: IMAGES.lion1.pic6,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.lion1.pic2,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.lion1.pic3,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.lion1.pic4,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 5,
                image: IMAGES.lion1.pic5,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 6,
                image: IMAGES.lion1.pic6,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 7,
                image: IMAGES.lion1.pic7,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 8,
                image: IMAGES.lion1.pic8,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 9,
                image: IMAGES.lion1.pic9,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 10,
                image: IMAGES.lion1.pic10,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 11,
                image: IMAGES.lion1.pic11,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 12,
                image: IMAGES.lion1.pic12,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
        ],
    },
    {
        id: 1,
        key: "case",
        type: "products.lion01.type",
        series: "Iphone 13, 14, 15 pro, pro max series",
        name: "Panther L1",
        description: "products.lion01.description",
        info: "Lorem",
        price: 10,
        discount: "$15",
        discountP: "-33%",
        colors: [COLORS.black, COLORS.darkBrown, COLORS.darkBlue],
        models: [
            "13 pro",
            "13 pro max",
            "14 pro",
            "14 pro max",
            "15 pro",
            "15 pro max",
        ],
        mediaWithInfo: [
            IMAGES.lion1.g,
            IMAGES.lion1.c,
            IMAGES.lion1.h,
            IMAGES.lion1.a,
            IMAGES.lion1.d,
        ],
        media: [
            {
                id: 1,
                image: IMAGES.lion1.pic9,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.lion1.pic2,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.lion1.pic3,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.lion1.pic4,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 5,
                image: IMAGES.lion1.pic5,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 6,
                image: IMAGES.lion1.pic6,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 7,
                image: IMAGES.lion1.pic7,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 8,
                image: IMAGES.lion1.pic8,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 9,
                image: IMAGES.lion1.pic9,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 10,
                image: IMAGES.lion1.pic10,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 11,
                image: IMAGES.lion1.pic11,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 12,
                image: IMAGES.lion1.pic12,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
        ],
    },
];
