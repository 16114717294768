import { observer } from "mobx-react-lite";
import React from "react";
import "./index.css";
import Router from "./routers/router";

function App() {
    return <Router />;
}

export default observer(App);
