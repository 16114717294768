import React from "react";
import { styled } from "styled-components";
import Text from "../Text/Text";
interface Props {
    img1?: string;
    img2?: string;
    img3?: string;
    title?: string;
    text?: string;
    padding?: string;
}

const TopContent: React.FC<Props> = ({
    img1,
    img2,
    img3,
    text,
    title,
    padding,
}) => {
    return (
        <TopContentContainer style={{ padding: padding }}>
            <img className="top_img1" src={img1} alt="" />
            <img className="top_img" src={img2} alt="" />
            <img className="top_img1" src={img3} alt="" />
            <div className="TopContent_title_Box">
                <Text textAlign={"center"} textSize="sixty" children={title} />
                <Text textAlign={"center"} textSize="twelve" children={text} />
            </div>
            <div className="TopContent_above_Box"></div>
        </TopContentContainer>
    );
};

export default TopContent;

const TopContentContainer = styled.div`
    display: grid;
    width: 100%;
    position: relative;
    grid-template-columns: 1fr 1fr 1fr;

    img {
        width: 100%;
        height: 70vh;
        object-fit: cover;
    }

    .TopContent_title_Box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }
    .TopContent_above_Box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 70vh;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 1;
    }

    @media (max-width: 992px) {
        grid-template-columns: 1fr;

        .top_img1 {
            display: none;
        }
    }
`;
