import { makeAutoObservable } from "mobx";
import {
    ProductDataInitial,
    ProductDataType,
    ProductMediaInitial,
    ProductMediaType,
    SetProductInitial,
    SetProductType,
} from "../types/product";
import { runInAction } from "mobx";
import { CasesData, WatchsData } from "../utils/db";
import { find } from "lodash";

export default class ProductStore {
    constructor() {
        makeAutoObservable(this);
        this.setBasket(
            JSON.parse(localStorage.getItem("basket") as never) || []
        );
    }

    productInfo: ProductDataType = ProductDataInitial;

    productMedia: ProductMediaType[] = [ProductMediaInitial];

    setProductState: SetProductType = SetProductInitial;

    mediaCurrent: ProductMediaType = ProductMediaInitial;

    basketData: SetProductType[] = [SetProductInitial];

    addCountProduct = (index: number) => {
        runInAction(() => {
            this.basketData[index].count++;
        });
        localStorage.setItem("basket", JSON.stringify(this.basketData));
    };
    reCountProduct = (index: number) => {
        if (this.basketData[index].count > 1) {
            runInAction(() => {
                this.basketData[index].count--;
            });
            localStorage.setItem("basket", JSON.stringify(this.basketData));
        }
    };

    getOneProduct = async (key: string, id: number) => {
        runInAction(() => {
            if (key === "case") {
                this.productInfo = find(CasesData, { id }) as ProductDataType;
                this.productMedia = this.productInfo.media;
                this.mediaCurrent = this.productMedia[0];
            }
            if (key === "watch") {
                this.productInfo = find(WatchsData, { id }) as ProductDataType;
                this.productMedia = this.productInfo.media;
                this.mediaCurrent = this.productInfo.media[0];
            }
            this.productDataToSetData(this.productInfo);
        });
    };

    clearProductInfo = () => {
        runInAction(() => {
            this.productInfo = ProductDataInitial;
        });
    };

    productDataToSetData = (data: ProductDataType) => {
        this.setProductState = {
            id: 1,
            image: data.media[0].image,
            name: data.name,
            color: "",
            count: 1,
            model: "",
            price: data.price,
            totalPrice: "",
            type: "",
            username: "",
            phoneNumber: "",
        };
    };

    clearSetProduct = () => {
        this.setProductState = {
            id: 1,
            image: this.productInfo.media[0].image,
            name: this.productInfo.name,
            color: "",
            count: 1,
            model: "",
            price: this.productInfo.price,
            totalPrice: "",
            type: "",
            username: "",
            phoneNumber: "",
        };
    };

    setMediaCurrent = (id: number) => {
        this.mediaCurrent = find(this.productInfo.media, { id }) as never;
        this.setProduct("image", this.mediaCurrent?.image);
    };

    setProduct = (key: keyof SetProductType, value: any) => {
        this.setProductState[key] = value as never;
    };

    setBasket = (data: SetProductType[]) => {
        runInAction(() => {
            this.basketData = data;
        });
    };

    setFilterMedia = (color: string) => {
        runInAction(() => {
            if (color) {
                this.productMedia = this.productInfo.media.filter(
                    (e) => e.color === color
                );
            }
            if (color === "") {
                this.productMedia = this.productInfo.media;
            }
        });
        this.setMediaCurrent(this.productMedia[0]?.id);
    };
}
