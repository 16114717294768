import { makeAutoObservable } from "mobx";

class VisibilityStore {
    constructor() {
        makeAutoObservable(this);
    }

    visible = {
        productInfo: false,
        basket: false,
        mediaMenu: false,
        initialLoading: false,
    };

    show = (key: keyof typeof this.visible) => {
        this.visible[key] = true;
    };

    hide = (key: keyof typeof this.visible) => {
        this.visible[key] = false;
    };

    toggle = (key: keyof typeof this.visible) => {
        this.visible[key] = !this.visible[key];
    };
}

export default VisibilityStore;
