import { ProductDataType } from "../types/product";
import { COLORS } from "./colors";
import { ICONS, IMAGES } from "./require";

export const CasesData: ProductDataType[] = [
    {
        id: 1,
        key: "case",
        type: "products.lion01.type",
        series: "Iphone 13, 14, 15 pro, pro max series",
        name: "Panther L1",
        description: "products.lion01.description",
        info: "Lorem",
        price: 12,
        discount: "$15",
        discountP: "-20%",
        colors: [COLORS.black, COLORS.darkBrown, COLORS.darkBlue],
        models: [
            "13 pro",
            "13 pro max",
            "14 pro",
            "14 pro max",
            "15 pro",
            "15 pro max",
        ],
        mediaWithInfo: [
            IMAGES.lion1.g,
            IMAGES.lion1.c,
            IMAGES.lion1.h,
            IMAGES.lion1.a,
            IMAGES.lion1.d,
        ],
        media: [
            {
                id: 1,
                image: IMAGES.lion1.pic1,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.lion1.pic2,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.lion1.pic3,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.lion1.pic4,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 5,
                image: IMAGES.lion1.pic5,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 6,
                image: IMAGES.lion1.pic6,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 7,
                image: IMAGES.lion1.pic7,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 8,
                image: IMAGES.lion1.pic8,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 9,
                image: IMAGES.lion1.pic9,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 10,
                image: IMAGES.lion1.pic10,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 11,
                image: IMAGES.lion1.pic11,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 12,
                image: IMAGES.lion1.pic12,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
        ],
    },
    {
        id: 2,
        key: "case",
        type: "products.lion02.type",
        series: "Iphone 13, 14, 15 pro, pro max series",
        name: "Panther S2",
        description: "products.lion02.description",
        info: "Lorem",
        price: 9,
        discount: "$15",
        discountP: "-35%",
        colors: [COLORS.black],
        models: [
            "13 pro",
            "13 pro max",
            "14 pro",
            "14 pro max",
            "15 pro",
            "15 pro max",
        ],
        mediaWithInfo: [IMAGES.lion2.i, IMAGES.lion2.b, IMAGES.lion2.a],
        media: [
            {
                id: 1,
                image: IMAGES.lion2.pic3,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.lion2.pic1,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.lion2.pic2,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.lion2.pic4,
                video: "",
                color: COLORS.black,
                type: "",
            },
        ],
    },
    {
        id: 3,
        key: "case",
        type: "products.wolf1.type",
        series: "Iphone 13, 14, 15 pro, pro max series",
        name: "Wolf L1",
        description: "products.wolf1.description",
        info: "Lorem",
        price: 15,
        discount: "$20",
        discountP: "-25%",
        colors: [COLORS.black, COLORS.darkBrown, COLORS.darkBlue],
        models: [
            "13 pro",
            "13 pro max",
            "14 pro",
            "14 pro max",
            "15 pro",
            "15 pro max",
        ],
        mediaWithInfo: [IMAGES.prContent3, IMAGES.wolf1.b, IMAGES.wolf1.pic],
        media: [
            {
                id: 1,
                image: IMAGES.wolf1.a,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.wolf1.b,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.wolf1.c,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.wolf1.d,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 5,
                image: IMAGES.wolf1.e,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 6,
                image: IMAGES.wolf1.f,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 7,
                image: IMAGES.wolf1.g,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 8,
                image: IMAGES.wolf1.h,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 9,
                image: IMAGES.wolf1.i,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 10,
                image: IMAGES.wolf1.j,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 11,
                image: IMAGES.wolf1.k,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
        ],
    },
    {
        id: 3,
        key: "case",
        type: "products.wolf1.type",
        series: "Iphone 13, 14, 15 pro, pro max series",
        name: "Wolf L1",
        description: "products.wolf1.description",
        info: "Lorem",
        price: 15,
        discount: "$20",
        discountP: "-25%",
        colors: [COLORS.black, COLORS.darkBrown, COLORS.darkBlue],
        models: [
            "13 pro",
            "13 pro max",
            "14 pro",
            "14 pro max",
            "15 pro",
            "15 pro max",
        ],
        mediaWithInfo: [IMAGES.wolf1.i, IMAGES.wolf1.b, IMAGES.wolf1.f],
        media: [
            {
                id: 1,
                image: IMAGES.wolf1.e,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.wolf1.b,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.wolf1.c,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.wolf1.d,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 5,
                image: IMAGES.wolf1.a,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 6,
                image: IMAGES.wolf1.f,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 7,
                image: IMAGES.wolf1.g,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 8,
                image: IMAGES.wolf1.h,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 9,
                image: IMAGES.wolf1.i,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 10,
                image: IMAGES.wolf1.j,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 11,
                image: IMAGES.wolf1.k,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
        ],
    },
    {
        id: 1,
        key: "case",
        type: "products.lion01.type",
        series: "Iphone 13, 14, 15 pro, pro max series",
        name: "Panther L1",
        description: "products.lion01.description",
        info: "Lorem",
        price: 12,
        discount: "$15",
        discountP: "-20%",
        colors: [COLORS.black, COLORS.darkBrown, COLORS.darkBlue],
        models: [
            "13 pro",
            "13 pro max",
            "14 pro",
            "14 pro max",
            "15 pro",
            "15 pro max",
        ],
        mediaWithInfo: [
            IMAGES.lion1.g,
            IMAGES.lion1.c,
            IMAGES.lion1.h,
            IMAGES.lion1.a,
            IMAGES.lion1.d,
        ],
        media: [
            {
                id: 1,
                image: IMAGES.lion1.pic6,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.lion1.pic2,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.lion1.pic3,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.lion1.pic4,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 5,
                image: IMAGES.lion1.pic5,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 6,
                image: IMAGES.lion1.pic1,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 7,
                image: IMAGES.lion1.pic7,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 8,
                image: IMAGES.lion1.pic8,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 9,
                image: IMAGES.lion1.pic9,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 10,
                image: IMAGES.lion1.pic10,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 11,
                image: IMAGES.lion1.pic11,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 12,
                image: IMAGES.lion1.pic12,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
        ],
    },
    {
        id: 4,
        key: "case",
        type: "products.wolf2.type",
        series: "Iphone 13, 14, 15 pro, pro max series",
        name: "Wolf S2",
        description: "products.wolf2.description",
        info: "Lorem",
        price: 7,
        discount: "$10",
        discountP: "-30%",
        colors: [COLORS.black, COLORS.red, COLORS.darkBlue, COLORS.purple],
        models: [
            "13 pro",
            "13 pro max",
            "14 pro",
            "14 pro max",
            "15 pro",
            "15 pro max",
        ],
        mediaWithInfo: [IMAGES.wolf2.c, IMAGES.wolf2.d, IMAGES.wolf2.g],
        media: [
            {
                id: 1,
                image: IMAGES.wolf2.d,
                video: "",
                color: COLORS.purple,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.wolf2.c,
                video: "",
                color: COLORS.purple,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.wolf2.a,
                video: "",
                color: COLORS.purple,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.wolf2.e,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 5,
                image: IMAGES.wolf2.g,
                video: "",
                color: COLORS.purple,
                type: "",
            },
            {
                id: 6,
                image: IMAGES.wolf2.h,
                video: "",
                color: COLORS.purple,
                type: "",
            },
            {
                id: 7,
                image: IMAGES.wolf2.i,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 8,
                image: IMAGES.wolf2.j,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 9,
                image: IMAGES.wolf2.k,
                video: "",
                color: COLORS.purple,
                type: "",
            },
            {
                id: 10,
                image: IMAGES.wolf2.l,
                video: "",
                color: COLORS.red,
                type: "",
            },
        ],
    },
];

export const WatchsData: ProductDataType[] = [
    {
        id: 1,
        key: "watch",
        type: "products.watch1.type",
        series: "Megnatic series",
        name: "Magnet L1",
        description: "products.watch1.description",
        info: "Lorem",
        price: 32,
        discount: "$50",
        discountP: "-30%",
        colors: [COLORS.black, COLORS.darkBrown],
        models: ["products.watch1.type"],
        mediaWithInfo: [IMAGES.watch1.c, IMAGES.watch1.h, IMAGES.watch1.j],
        media: [
            {
                id: 1,
                image: IMAGES.watch1.first,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.watch1.a,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.watch1.b,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.watch1.c,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 5,
                image: IMAGES.watch1.d,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 6,
                image: IMAGES.watch1.e,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 7,
                image: IMAGES.watch1.f,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 8,
                image: IMAGES.watch1.g,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 9,
                image: IMAGES.watch1.h,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 10,
                image: IMAGES.watch1.i,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 11,
                image: IMAGES.watch1.j,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 12,
                image: IMAGES.watch1.l,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
        ],
    },
    {
        id: 2,
        key: "watch",
        type: "products.watch2.type",
        series: "Megnatic series",
        name: "Magnet I2",
        description: "products.watch2.description",
        info: "Lorem",
        price: 40,
        discount: "$55",
        discountP: "-20%",
        colors: [COLORS.silver, COLORS.green],
        models: ["products.watch2.type"],
        mediaWithInfo: [IMAGES.watch2.a, IMAGES.watch2.b, IMAGES.watch2.d],
        media: [
            {
                id: 1,
                image: IMAGES.watch2.first,
                video: "",
                color: COLORS.silver,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.watch2.a,
                video: "",
                color: COLORS.green,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.watch2.b,
                video: "",
                color: COLORS.green,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.watch2.c,
                video: "",
                color: COLORS.green,
                type: "",
            },
            {
                id: 5,
                image: IMAGES.watch2.d,
                video: "",
                color: COLORS.green,
                type: "",
            },

            {
                id: 6,
                image: IMAGES.watch2.f,
                video: "",
                color: COLORS.silver,
                type: "",
            },
            {
                id: 7,
                image: IMAGES.watch2.g,
                video: "",
                color: COLORS.green,
                type: "",
            },
        ],
    },
    {
        id: 3,
        key: "watch",
        type: "products.watch3.type",
        series: "Megnatic series",
        name: "Magnet L3",
        description: "products.watch3.description",
        info: "Lorem",
        price: 45,
        discount: "$65",
        discountP: "-22%",
        colors: [COLORS.black, COLORS.yellow],
        models: ["products.watch3.type"],
        mediaWithInfo: [IMAGES.watch3.h, IMAGES.watch3.f, IMAGES.watch3.j],
        media: [
            {
                id: 1,
                image: IMAGES.watch3.h,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.watch3.a,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.watch3.f,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.watch3.b,
                video: "",
                color: COLORS.yellow,
                type: "",
            },
            {
                id: 5,
                image: IMAGES.watch3.g,
                video: "",
                color: COLORS.yellow,
                type: "",
            },
            {
                id: 6,
                image: IMAGES.watch3.j,
                video: "",
                color: COLORS.yellow,
                type: "",
            },
        ],
    },
    {
        id: 4,
        key: "watch",
        type: "products.watch4.type",
        series: "Megnatic series",
        name: "Magnet S1",
        description: "products.watch4.description",
        info: "Lorem",
        price: 60,
        discount: "$85",
        discountP: "28%",
        colors: [COLORS.black, COLORS.silver],
        models: ["products.watch4.type"],
        mediaWithInfo: [IMAGES.watch4.a, IMAGES.watch4.b, IMAGES.watch4.d],
        media: [
            {
                id: 1,
                image: IMAGES.watch4.b,
                video: "",
                color: COLORS.silver,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.watch4.a,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.watch4.b,
                video: "",
                color: COLORS.silver,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.watch4.c,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 5,
                image: IMAGES.watch4.d,
                video: "",
                color: COLORS.silver,
                type: "",
            },
        ],
    },
    {
        id: 5,
        key: "watch",
        type: "products.watch5.type",
        series: "Megnatic series",
        name: "Mexan S1",
        description: "products.watch5.description",
        info: "Lorem",
        price: 22,
        discount: "$30",
        discountP: "-25%",
        colors: [
            COLORS.black,
            COLORS.darkBrown,
            COLORS.dimGray,
            COLORS.darkGreen,
            COLORS.saddleBrown,
        ],
        models: ["products.watch5.type"],
        mediaWithInfo: [
            IMAGES.watch5.pic4,
            IMAGES.watch5.pic2,
            IMAGES.watch5.pic5,
        ],
        media: [
            {
                id: 1,
                image: IMAGES.watch5.pic1,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.watch5.pic6,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.watch5.pic3,
                video: "",
                color: COLORS.darkGreen,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.watch5.pic4,
                video: "",
                color: COLORS.darkGreen,
                type: "",
            },
            {
                id: 5,
                image: IMAGES.watch5.pic5,
                video: "",
                color: COLORS.darkGreen,
                type: "",
            },
            {
                id: 6,
                image: IMAGES.watch5.pic7,
                video: "",
                color: COLORS.darkGreen,
                type: "",
            },
            {
                id: 7,
                image: IMAGES.watch5.pic2,
                video: "",
                color: COLORS.saddleBrown,
                type: "",
            },
            {
                id: 8,
                image: IMAGES.watch5.pic9,
                video: "",
                color: COLORS.saddleBrown,
                type: "",
            },

            {
                id: 9,
                image: IMAGES.watch5.pic8,
                video: "",
                color: COLORS.dimGray,
                type: "",
            },
            {
                id: 10,
                image: IMAGES.watch5.pic10,
                video: "",
                color: COLORS.black,
                type: "",
            },
        ],
    },
    {
        id: 1,
        key: "watch",
        type: "products.watch1.type",
        series: "Megnatic series",
        name: "Magnet L1",
        description: "products.watch1.description",
        info: "Lorem",
        price: 32,
        discount: "$50",
        discountP: "-30%",
        colors: [COLORS.black, COLORS.darkBrown],
        models: ["products.watch1.type"],
        mediaWithInfo: [IMAGES.watch1.c, IMAGES.watch1.h, IMAGES.watch1.j],
        media: [
            {
                id: 1,
                image: IMAGES.watch1.d,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.watch1.a,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.watch1.b,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.watch1.c,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 5,
                image: IMAGES.watch1.first,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 6,
                image: IMAGES.watch1.e,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 7,
                image: IMAGES.watch1.f,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 8,
                image: IMAGES.watch1.g,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 9,
                image: IMAGES.watch1.h,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 10,
                image: IMAGES.watch1.i,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 11,
                image: IMAGES.watch1.j,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 12,
                image: IMAGES.watch1.l,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
        ],
    },
];

export const DeliveryVariantsData = [
    {
        id: 1,
        icon: ICONS.dvIcon1,
        type: "delivery.firstDelivery",
        price: "delivery.firstDeliveryPrice",
        info: "delivery.firstDeliveryInfo",
    },
    {
        id: 2,
        icon: ICONS.dvIcon2,
        type: "delivery.secondDelivery",
        price: "delivery.secondDeliveryPrice",
        info: "delivery.secondDeliveryInfo",
    },
    {
        id: 3,
        icon: ICONS.dvIcon3,
        type: "delivery.thirdDelivery",
        price: "delivery.thirdDeliveryPrice",
        info: "delivery.thirdDeliveryInfo",
    },
];
