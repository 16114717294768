import React from "react";
import { styled } from "styled-components";
import styles from "./Text.module.css";

interface Props {
    children?: any;
    textSize:
        | "twelve"
        | "fourteen"
        | "sixteen"
        | "eighteen"
        | "twenty"
        | "twentyTwo"
        | "twentyEight"
        | "thirtySix"
        | "forty"
        | "fifty"
        | "sixty"
        | "seventy";
    margin?: string;
    textColor?: string;
    textAlign?: any;
    fontWeight?: string;
    lineHeight?: string;
    textDec?: string;
}

const Text: React.FC<Props> = ({
    children,
    textSize,
    margin,
    textColor,
    textAlign,
    fontWeight,
    lineHeight,
    textDec,
}) => {
    return (
        <p
            className={`${styles.text} ${styles[textSize]}`}
            style={{
                margin: margin,
                color: textColor,
                textAlign: textAlign,
                fontWeight: fontWeight,
                lineHeight: lineHeight,
                textDecoration: textDec,
            }}
        >
            {children}
        </p>
    );
};

export default Text;
