export const IMAGES = {
    penther: require("../assets/images/panther.PNG"),
    serious: require("../assets/images/serious.PNG"),
    uncommon: require("../assets/images/uncommon.PNG"),
    lion1: {
        pic1: require("../assets/images/lion1/IMG_3385.webp"),
        pic2: require("../assets/images/lion1/IMG_3388.webp"),
        pic3: require("../assets/images/lion1/IMG_3389.webp"),
        pic4: require("../assets/images/lion1/IMG_3393.webp"),
        pic5: require("../assets/images/lion1/IMG_3396.webp"),
        pic6: require("../assets/images/lion1/IMG_3397.webp"),
        pic7: require("../assets/images/lion1/IMG_3398.webp"),
        pic8: require("../assets/images/lion1/IMG_3399.webp"),
        pic9: require("../assets/images/lion1/IMG_3400.webp"),
        pic10: require("../assets/images/lion1/IMG_3402.webp"),
        pic11: require("../assets/images/lion1/IMG_3403.webp"),
        pic12: require("../assets/images/lion1/IMG_3404.webp"),
        a: require("../assets/images/lion1/lion1a.jpg"),
        b: require("../assets/images/lion1/lion1b.JPG"),
        c: require("../assets/images/lion1/lion1c.JPG"),
        d: require("../assets/images/lion1/lion1d.JPG"),
        e: require("../assets/images/lion1/lion1e.JPG"),
        f: require("../assets/images/lion1/lion1f.JPG"),
        g: require("../assets/images/lion1/lion1g.JPG"),
        h: require("../assets/images/lion1/lion1h.JPG"),
        i: require("../assets/images/lion1/lion1i.JPG"),
        j: require("../assets/images/lion1/lion1j.jpg"),
    },
    lion2: {
        a: require("../assets/images/lion2/lion2a.png"),
        b: require("../assets/images/lion2/lion2b.png"),
        c: require("../assets/images/lion2/lion2c.jpeg"),
        d: require("../assets/images/lion2/lion2d.png"),
        e: require("../assets/images/lion2/lion2e.jpg"),
        f: require("../assets/images/lion2/lion2f.jpg"),
        g: require("../assets/images/lion2/lion2g.jpg"),
        i: require("../assets/images/lion2/lion2i.webp"),
        pic1: require("../assets/images/lion2/FullSizeRender.webp"),
        pic2: require("../assets/images/lion2/IMG_2116.webp"),
        pic3: require("../assets/images/lion2/IMG_2152.webp"),
        pic4: require("../assets/images/lion2/IMG_2154.webp"),
        pic5: require("../assets/images/lion2/IMG_3434.webp"),
        pic6: require("../assets/images/lion2/IMG_3437.webp"),
        pic7: require("../assets/images/lion2/IMG_3439.webp"),
        pic8: require("../assets/images/lion2/IMG_3440.webp"),
        pic9: require("../assets/images/lion2/lion2pic.webp"),
    },
    wolf1: {
        a: require("../assets/images/wolf1/1.1.JPG"),
        b: require("../assets/images/wolf1/1.2.JPG"),
        c: require("../assets/images/wolf1/1.5.JPG"),
        d: require("../assets/images/wolf1/1.6.JPG"),
        e: require("../assets/images/wolf1/2.1.JPG"),
        f: require("../assets/images/wolf1/2.2.JPG"),
        g: require("../assets/images/wolf1/2.7.JPG"),
        h: require("../assets/images/wolf1/3.1.JPG"),
        i: require("../assets/images/wolf1/3.2.JPG"),
        j: require("../assets/images/wolf1/3.3.JPG"),
        k: require("../assets/images/wolf1/3.6.JPG"),
        pic: require("../assets/images/wolf1/wolf1pic.JPG"),
    },
    wolf2: {
        a: require("../assets/images/wolf2/wolf2a.JPG"),
        b: require("../assets/images/wolf2/wolf2b.png"),
        c: require("../assets/images/wolf2/wolf2c.JPG"),
        d: require("../assets/images/wolf2/wolf2d.JPG"),
        e: require("../assets/images/wolf2/wolf2e.JPG"),
        f: require("../assets/images/wolf2/wolf2f.JPG"),
        g: require("../assets/images/wolf2/wolf2g.JPG"),
        h: require("../assets/images/wolf2/wolf2h.webp"),
        i: require("../assets/images/wolf2/wolf2i.webp"),
        j: require("../assets/images/wolf2/wolf2j.webp"),
        k: require("../assets/images/wolf2/wolf2k.webp"),
        l: require("../assets/images/wolf2/wolf2l.webp"),
        m: require("../assets/images/wolf2/wolf2m.webp"),
        n: require("../assets/images/wolf2/wolf2n.webp"),
    },
    watch1: {
        first: require("../assets/images/watch1/watch1.JPG"),
        a: require("../assets/images/watch1/watch1a.JPG"),
        b: require("../assets/images/watch1/watch1b.JPG"),
        c: require("../assets/images/watch1/watch1c.JPG"),
        d: require("../assets/images/watch1/watch1d.JPG"),
        e: require("../assets/images/watch1/watch1e.JPG"),
        f: require("../assets/images/watch1/watch1f.JPG"),
        g: require("../assets/images/watch1/watch1g.JPG"),
        h: require("../assets/images/watch1/watch1h.jpg"),
        i: require("../assets/images/watch1/watch1i.webp"),
        j: require("../assets/images/watch1/watch1j.jpg"),
        k: require("../assets/images/watch1/watch1k.webp"),
        l: require("../assets/images/watch1/watch1l.webp"),
    },
    watch2: {
        first: require("../assets/images/watch2/watch2.JPG"),
        a: require("../assets/images/watch2/watch2a.JPG"),
        b: require("../assets/images/watch2/watch2b.JPG"),
        c: require("../assets/images/watch2/watch2c.JPG"),
        d: require("../assets/images/watch2/watch2d.JPG"),
        e: require("../assets/images/watch2/watch2e.JPG"),
        f: require("../assets/images/watch2/watch2f.webp"),
        g: require("../assets/images/watch2/watch2g.webp"),
        h: require("../assets/images/watch2/watch2h.webp"),
    },
    watch3: {
        a: require("../assets/images/watch3/watch3a.webp"),
        b: require("../assets/images/watch3/watch3b.JPG"),
        c: require("../assets/images/watch3/watch3c.JPG"),
        d: require("../assets/images/watch3/watch3d.JPG"),
        e: require("../assets/images/watch3/watch3e.JPG"),
        f: require("../assets/images/watch3/watch3f.jpg"),
        g: require("../assets/images/watch3/watch3g.webp"),
        h: require("../assets/images/watch3/watch3h.webp"),
        i: require("../assets/images/watch3/watch3i.JPG"),
        j: require("../assets/images/watch3/watch3j.webp"),
    },
    watch4: {
        a: require("../assets/images/watch4/watch4a.jpeg"),
        b: require("../assets/images/watch4/watch4b.jpeg"),
        c: require("../assets/images/watch4/watch4c.jpeg"),
        d: require("../assets/images/watch4/watch4d.jpeg"),
    },
    watch5: {
        pic1: require("../assets/images/watch5/IMG_6415.JPG"),
        pic2: require("../assets/images/watch5/IMG_6416.jpg"),
        pic3: require("../assets/images/watch5/IMG_6417.jpg"),
        pic4: require("../assets/images/watch5/IMG_6418.JPG"),
        pic5: require("../assets/images/watch5/IMG_6419.JPG"),
        pic6: require("../assets/images/watch5/IMG_6420.JPG"),
        pic7: require("../assets/images/watch5/IMG_6422.jpg"),
        pic8: require("../assets/images/watch5/IMG_6423.jpg"),
        pic9: require("../assets/images/watch5/IMG_6424.jpg"),
        pic10: require("../assets/images/watch5/IMG_6429.jpg"),
    },
    bg1: require("../assets/images/bg/bg-1.jpg"),
    bg2: require("../assets/images/bg/bg-2.webp"),
    bg3: require("../assets/images/bg/bg-3.jpg"),
    bg4: require("../assets/images/bg/bg-4.jpeg"),
    bg5: require("../assets/images/bg/bg-5.jpg"),
    category1: require("../assets/images/category1.JPG"),
    category2: require("../assets/images/category2.jpeg"),
    prContent1: require("../assets/images/prContent1.JPG"),
    prContent2: require("../assets/images/prContent2.JPG"),
    prContent3: require("../assets/images/prContent3.JPG"),
};

export const ICONS = {
    dvIcon1: require("../assets/icons/dvIcon1.png"),
    dvIcon2: require("../assets/icons/dvIcon2.png"),
    dvIcon3: require("../assets/icons/dvIcon3.png"),
};
