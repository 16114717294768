import { styled } from "styled-components";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { FaTelegram } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IMAGES } from "../../utils/require";

const Footer = () => {
    const { t } = useTranslation();
    const navigation = useNavigate();

    return (
        <FooterComponent>
            <span onClick={() => navigation("/")}>
                <img className="logo" src={IMAGES.uncommon} alt="" />
            </span>
            <div className="category">
                <div className="item">
                    <a aria-label="Cases content" href="#">
                        {t("header.cases")}
                    </a>
                    <a aria-label="Watchs content" href="#">
                        {t("header.watchs")}
                    </a>
                </div>
                <div className="item">
                    <a aria-label="Phone number" href="tel:+998 (91) 011 4855">
                        +998 99 030 46 32
                    </a>
                    <div className="social">
                        <a
                            aria-label="Instagram profile"
                            href="https://www.instagram.com/uncommontech.uz"
                            target={"_blank"}
                        >
                            <BiLogoInstagramAlt size={24} className="icon" />
                        </a>
                        <a
                            aria-label="Telegram channel"
                            href="https://t.me/uncommontechuz"
                            target={"_blank"}
                        >
                            <FaTelegram size={24} />
                        </a>
                    </div>
                </div>
            </div>
        </FooterComponent>
    );
};

export default Footer;

const FooterComponent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #000;
    height: 140px;
    width: 100%;
    color: #fff;
    padding: 0 4%;
    position: relative;

    .logo {
        width: 200px;
    }

    .category {
        display: flex;
        align-items: center;
        gap: 20px;

        a {
            text-decoration: none;
            color: #fff;
            font-weight: 600;
        }

        .item {
            display: flex;
            align-items: center;
            gap: 20px;

            .social {
                display: flex;
                gap: 20px;

                .icon {
                    background-color: #fff;
                    color: #000;
                    border-radius: 50%;
                }
            }
        }
    }

    @media (max-width: 776px) {
        height: 100%;
        padding: 30px 4%;
        align-items: flex-start;
        gap: 0;

        span {
            display: none;
        }

        .category {
            align-items: flex-start;

            .item {
                flex-direction: column;
                align-items: flex-start;
            }

            .social {
                display: flex;
                gap: 10px;
            }
        }
    }
`;
