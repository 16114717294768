import React from "react";
import { styled } from "styled-components";
import Text from "../Text/Text";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { COLORS } from "../../utils/colors";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";

interface Props {
    image: string;
    name?: string;
    count?: string;
    price?: number | string;
    onRemove?: () => void;
    addCount?: () => void;
    reCount?: () => void;
}

const BasketCard: React.FC<Props> = ({
    image,
    count,
    name,
    onRemove,
    price,
    addCount,
    reCount,
}) => {
    return (
        <BasketItemComponent>
            <div className="imageName">
                <img src={image} alt="" />
                <Text
                    children={name}
                    textColor={COLORS.black}
                    textSize="sixteen"
                />
            </div>
            <div className="countBox">
                <AiOutlineMinusCircle size={22} onClick={reCount} />
                <Text
                    children={count}
                    textColor={COLORS.black}
                    textSize="twenty"
                />
                <AiOutlinePlusCircle size={22} onClick={addCount} />
            </div>
            <Text
                children={price}
                textColor={COLORS.black}
                textSize="sixteen"
            />
            <AiOutlineCloseCircle size={24} onClick={onRemove} />
        </BasketItemComponent>
    );
};

export default BasketCard;

const BasketItemComponent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 10px 10px 0;

    .imageName {
        display: flex;
        gap: 5px;
        align-items: center;

        img {
            width: 60px;
            height: 80px;
            border-radius: 10px;
            object-fit: cover;
        }
    }
    .countBox {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    @media (max-width: 500px) {
        padding: 10px 0;

        .countBox {
            flex-direction: column;
        }

        .imageName {
            width: 50%;

            img {
                width: 50px;
                height: 70px;
            }
        }
    }
`;
