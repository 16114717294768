import { observer } from "mobx-react-lite";
import React from "react";
import { styled } from "styled-components";
interface Props {
    imgUrl1?: string;
    imgUrl2?: string;
    imgUrl3?: string;
}

const JustMedia: React.FC<Props> = ({ imgUrl1, imgUrl2, imgUrl3 }) => {
    return (
        <JustMediaComponent>
            <img src={imgUrl1} alt="" />
            <img src={imgUrl2} alt="" />
            <img src={imgUrl3} alt="" />
        </JustMediaComponent>
    );
};

export default observer(JustMedia);

const JustMediaComponent = styled.div`
    display: flex;
    height: 600px;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 70px;

    img {
        width: 33.3%;
        height: 100%;
        object-fit: cover;
    }

    @media (max-width: 767px) {
        img {
            width: 100%;
        }
        flex-direction: column;
        height: 100%;
    }
`;
