import React from "react";
import { styled } from "styled-components";
import { COLORS } from "../../utils/colors";
import Text from "../Text/Text";

interface Props {
    children?: string;
}

const TextBanner: React.FC<Props> = ({ children }) => {
    return (
        <TextBannerComponent>
            <Text
                children={children}
                textSize="thirtySix"
                textColor={COLORS.black}
                textAlign="center"
            />
        </TextBannerComponent>
    );
};

export default TextBanner;

const TextBannerComponent = styled.div`
    height: 600px;
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    padding: 80px 20%;
    font-weight: 300;
    line-height: 50px;

    @media (max-width: 776px) {
        padding: 80px 10%;
    }

    @media (max-width: 576px) {
        height: 100%;
        padding: 100px 5%;
        line-height: 35px;
    }
`;
