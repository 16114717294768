import React from "react";
import { styled } from "styled-components";

interface Props {
    placeholder?: string;
    onChange: (e: any) => void;
    width?: string;
    value?: any;
    borderRadius?: string;
    border?: string;
}

const Input: React.FC<Props> = ({
    placeholder,
    onChange,
    width,
    value,
    borderRadius,
    border,
}) => {
    return (
        <InputComponent
            type="text"
            id="input-component"
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            style={{ width: width, borderRadius: borderRadius, border: border }}
            value={value}
        ></InputComponent>
    );
};

export default Input;

const InputComponent = styled.input`
    padding: 15px 20px;
    background-color: #fff;
    border: none;
    outline: none;
`;
