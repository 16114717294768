import { ProductDataType } from "../types/product";
import { COLORS } from "./colors";
import { IMAGES } from "./require";

export const WolfL1Data: ProductDataType[] = [
    {
        id: 3,
        key: "case",
        type: "products.wolf1.type",
        series: "Iphone 13, 14, 15 pro, pro max series",
        name: "Wolf L1",
        description: "products.wolf1.description",
        info: "Lorem",
        price: 14,
        discount: "$20",
        discountP: "-30%",
        colors: [COLORS.black, COLORS.darkBrown, COLORS.darkBlue],
        models: [
            "13 pro",
            "13 pro max",
            "14 pro",
            "14 pro max",
            "15 pro",
            "15 pro max",
        ],
        mediaWithInfo: [IMAGES.wolf1.i, IMAGES.wolf1.b, IMAGES.wolf1.f],
        media: [
            {
                id: 1,
                image: IMAGES.wolf1.a,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.wolf1.b,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.wolf1.c,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.wolf1.d,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 5,
                image: IMAGES.wolf1.e,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 6,
                image: IMAGES.wolf1.f,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 7,
                image: IMAGES.wolf1.g,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 8,
                image: IMAGES.wolf1.h,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 9,
                image: IMAGES.wolf1.i,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 10,
                image: IMAGES.wolf1.j,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 11,
                image: IMAGES.wolf1.k,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
        ],
    },
    {
        id: 3,
        key: "case",
        type: "products.wolf1.type",
        series: "Iphone 13, 14, 15 pro, pro max series",
        name: "Wolf L1",
        description: "products.wolf1.description",
        info: "Lorem",
        price: 14,
        discount: "$20",
        discountP: "-30%",
        colors: [COLORS.black, COLORS.darkBrown, COLORS.darkBlue],
        models: [
            "13 pro",
            "13 pro max",
            "14 pro",
            "14 pro max",
            "15 pro",
            "15 pro max",
        ],
        mediaWithInfo: [IMAGES.wolf1.i, IMAGES.wolf1.b, IMAGES.wolf1.f],
        media: [
            {
                id: 1,
                image: IMAGES.wolf1.e,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.wolf1.b,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.wolf1.c,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.wolf1.d,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 5,
                image: IMAGES.wolf1.a,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 6,
                image: IMAGES.wolf1.f,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 7,
                image: IMAGES.wolf1.g,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 8,
                image: IMAGES.wolf1.h,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 9,
                image: IMAGES.wolf1.i,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 10,
                image: IMAGES.wolf1.j,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 11,
                image: IMAGES.wolf1.k,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
        ],
    },
    {
        id: 3,
        key: "case",
        type: "products.wolf1.type",
        series: "Iphone 13, 14, 15 pro, pro max series",
        name: "Wolf L1",
        description: "products.wolf1.description",
        info: "Lorem",
        price: 14,
        discount: "$20",
        discountP: "-30%",
        colors: [COLORS.black, COLORS.darkBrown, COLORS.darkBlue],
        models: [
            "13 pro",
            "13 pro max",
            "14 pro",
            "14 pro max",
            "15 pro",
            "15 pro max",
        ],
        mediaWithInfo: [IMAGES.wolf1.i, IMAGES.wolf1.b, IMAGES.wolf1.f],
        media: [
            {
                id: 1,
                image: IMAGES.wolf1.h,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 2,
                image: IMAGES.wolf1.b,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 3,
                image: IMAGES.wolf1.c,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 4,
                image: IMAGES.wolf1.d,
                video: "",
                color: COLORS.black,
                type: "",
            },
            {
                id: 5,
                image: IMAGES.wolf1.e,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 6,
                image: IMAGES.wolf1.f,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 7,
                image: IMAGES.wolf1.g,
                video: "",
                color: COLORS.darkBlue,
                type: "",
            },
            {
                id: 8,
                image: IMAGES.wolf1.a,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 9,
                image: IMAGES.wolf1.i,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 10,
                image: IMAGES.wolf1.j,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
            {
                id: 11,
                image: IMAGES.wolf1.k,
                video: "",
                color: COLORS.darkBrown,
                type: "",
            },
        ],
    },
];
