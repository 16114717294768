import Header from "../../components/Header/Header";
import MainContent from "../../components/MainContent/MainContent";
import { IMAGES } from "../../utils/require";
import { BsChevronDown } from "react-icons/bs";
import ProductList from "../../components/ProductList/ProductList";
import { CasesData, WatchsData } from "../../utils/db";
import TextBanner from "../../components/TextBanner/TextBanner";
import Delivery from "../../components/Delivery/Delivery";
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/Footer";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Basket from "../../components/Basket/Basket";
import useRootStore from "../../hooks/useRootStore";
import { styled } from "styled-components";
import BasketBox from "../../components/BasketBox/BasketBox";
import Text from "../../components/Text/Text";
import { COLORS } from "../../utils/colors";
import Button from "../../components/Button/Button";
import CategoryContent from "../../components/CategoryContent/CategoryContent";
import ProductContent from "../../components/ProductContent/ProductContent";

const Homeview = () => {
    const navigation = useNavigate();
    const { t } = useTranslation();
    const { visible, show } = useRootStore().visibleStore;
    const { basketData } = useRootStore().productStore;

    return (
        <Home>
            <Header backColor="rgba(0, 0, 0, 0.1)" />
            <ProductContent
                imgUrl1={IMAGES.lion2.pic1}
                imgUrl2={IMAGES.prContent3}
                title="Uncommon Tech"
                text={t("mainContent.text")}
                backRgb={true}
                icon={<BsChevronDown size={50} color="#fff" />}
                downBtn={true}
            />
            <Text
                textSize="twentyEight"
                children={"Быстрый взгляд на"}
                textColor={COLORS.black}
                textAlign="center"
                margin="50px 0 0 0"
                fontWeight="700"
            />
            <Text
                textSize="sixty"
                children={"Покупайте лучшие коллекции"}
                textColor={COLORS.black}
                textAlign="center"
                margin="0 0 30px 0"
                fontWeight="700"
            />
            <CategoryContent />

            <Text
                textSize="twentyEight"
                children={"Быстрый взгляд на"}
                textColor={COLORS.black}
                textAlign="center"
                margin="50px 0 0 0"
                fontWeight="700"
            />
            <Text
                textSize="sixty"
                children={"Чехлы на айфон"}
                textColor={COLORS.black}
                textAlign="center"
                fontWeight="700"
            />
            <ProductList id="cases" products={CasesData} seeAllBtn={true} />
            <div className="seeAll">
                <Button
                    onClick={() => navigation("cases")}
                    children={t("seeAll")}
                    width="200px"
                    padding="10px 0"
                    textSize="16px"
                />
            </div>
            <MainContent
                image={IMAGES.watch3.a}
                height="600px"
                backPosition="center"
                title={t("watchCollections")}
                backRgb={true}
            />

            <Text
                textSize="twentyEight"
                children={"Быстрый взгляд на"}
                textColor={COLORS.black}
                textAlign="center"
                margin="50px 0 0 0"
                fontWeight="700"
            />
            <Text
                textSize="sixty"
                children={"Магнитные Часы"}
                textColor={COLORS.black}
                textAlign="center"
                fontWeight="700"
            />

            <ProductList id="watchs" products={WatchsData} seeAllBtn={true} />
            <div className="seeAll">
                <Button
                    onClick={() => navigation("watchs")}
                    children={t("seeAll")}
                    width="200px"
                    padding="10px 0"
                    textSize="16px"
                />
            </div>
            <TextBanner children={t("about")} />
            <Delivery />
            <Contact />
            <Footer />
            <Basket />
            {basketData.length && !visible.basket ? (
                <BasketBox
                    count={basketData.length}
                    openBasket={() => show("basket")}
                />
            ) : null}
        </Home>
    );
};

export default observer(Homeview);

const Home = styled.div`
    position: relative;
    .seeAll {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-bottom: 30px;
    }
`;
