import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { styled } from "styled-components";
import useRootStore from "../../hooks/useRootStore";
import ProductInfoCard from "../../components/ProductInfoCard/ProductInfoCard";
import ProductModels from "../../components/ProductModels/ProductModels";
import Button from "../../components/Button/Button";
import MediaCard from "../../components/MediaCard/MediaCard";
import { useTranslation } from "react-i18next";
import Basket from "../../components/Basket/Basket";
import BasketBox from "../../components/BasketBox/BasketBox";
import { message } from "antd";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import JustMedia from "../../components/JustMedia/JustMedia";

const ProductInfo = () => {
    const {
        productInfo,
        setProduct,
        setProductState,
        basketData,
        setBasket,
        setFilterMedia,
        clearSetProduct,
    } = useRootStore().productStore;
    const { show, visible } = useRootStore().visibleStore;
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    const ChooseModel = (e: any) => {
        if (setProductState.model === e) {
            setProduct("model", "");
        } else {
            setProduct("model", e);
        }
    };

    const ChooseColor = (e: any) => {
        if (setProductState.color === e) {
            setProduct("color", "");
            setFilterMedia("");
        } else {
            setProduct("color", e);
            setFilterMedia(e);
        }
    };

    const AddBasket = () => {
        if (setProductState.color && setProductState.model) {
            setProduct("id", basketData.length + 1);
            const basket =
                JSON.parse(localStorage.getItem("basket") as never) || [];

            basket.push(setProductState);

            localStorage.setItem("basket", JSON.stringify(basket));
            setBasket(basket);
            show("basket");
            clearSetProduct();
            setFilterMedia("");
        } else message.warning(t("error_model"));
    };

    return (
        <>
            <Header backColor="rgba(0,0,0, 0.8)" />
            <ProductInfoComponent>
                <div className="infoBox">
                    <div className="mediaBox">
                        <MediaCard />
                    </div>
                    <div className="textInfo">
                        <ProductInfoCard
                            name={productInfo.name}
                            type={t(productInfo.type)}
                            description={t(productInfo.description)}
                        />
                        <ProductModels
                            chooseModel={(e) => ChooseModel(e)}
                            chooseColor={(e) => ChooseColor(e)}
                            price={`$${productInfo.price}`}
                            discount={productInfo.discount}
                            discountP={productInfo.discountP}
                            colors={productInfo.colors}
                            models={productInfo.models}
                        />
                        <div className="form">
                            <Button
                                onClick={AddBasket}
                                children={t("add_basket")}
                            />
                        </div>
                    </div>
                </div>
                <JustMedia
                    imgUrl1={productInfo.mediaWithInfo[0]}
                    imgUrl2={productInfo.mediaWithInfo[1]}
                    imgUrl3={productInfo.mediaWithInfo[2]}
                />
            </ProductInfoComponent>
            <Basket />
            {basketData.length && !visible.basket ? (
                <BasketBox
                    count={basketData.length}
                    openBasket={() => show("basket")}
                />
            ) : null}
            <Footer />
        </>
    );
};

export default observer(ProductInfo);

const ProductInfoComponent = styled.section`
    position: relative;
    width: 100%;
    padding: 130px 1% 50px 1%;
    /* background-color: #ffffff; */

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    .back {
        display: flex;
        align-items: center;
        position: absolute;
        gap: 5px;
        top: 20px;
        left: 20px;
        cursor: pointer;
    }

    .infoBox {
        display: flex;
        justify-content: space-between;

        .mediaBox {
            width: 60%;
        }

        .textInfo {
            position: relative;
            width: 40%;
            padding: 0px 30px;
            border-radius: 10px;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            height: 100%;

            .form {
                display: flex;
                margin: 20px 0;
            }
        }

        @media (max-width: 1200px) {
            padding: 20px 0 50px 0;
        }

        @media (max-width: 992px) {
            flex-direction: column;
            gap: 30px;

            .mediaBox {
                width: 100%;
            }
            .textInfo {
                width: 100%;
                padding: 0px 15px;
            }
        }
    }
    @media (max-width: 576px) {
        padding: 100px 5%;
    }
`;
