import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import useRootStore from "../hooks/useRootStore";
import CasesView from "../views/cases/Cases";
import Homeview from "../views/home/view";
import ProductInfo from "../views/productInfo/ProductInfo";
import WatchsView from "../views/watchs/watchs";

const Router = () => {
    const { productInfo, getOneProduct } = useRootStore().productStore;
    const pathname = window.location.pathname;
    const product = pathname.split("/")[1];
    const id = Number(pathname.split("/")[2]);

    useEffect(() => {
        if (product && id) {
            getOneProduct(product, id as never);
        }
    }, []);

    return (
        <Routes>
            <Route path="/" element={<Homeview />}></Route>
            <Route path="/:product/:id" element={<ProductInfo />}></Route>
            <Route path="/cases" element={<CasesView />}></Route>
            <Route path="/watchs" element={<WatchsView />}></Route>
        </Routes>
    );
};

export default observer(Router);
