import React from "react";
import { observer } from "mobx-react-lite";
import { styled } from "styled-components";
import useRootStore from "../../hooks/useRootStore";
import { ProductDataType } from "../../types/product";
import { COLORS } from "../../utils/colors";
import Text from "../Text/Text";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
    products: ProductDataType[];
    id?: string;
    seeAll?: () => void;
    seeAllBtn?: boolean;
}

const ProductList: React.FC<Props> = ({ products, id, seeAll, seeAllBtn }) => {
    const navigation = useNavigate();
    const { getOneProduct, productInfo } = useRootStore().productStore;
    const { t } = useTranslation();

    const getProductInfo = (key: string, id: number) => {
        getOneProduct(key, id);
        navigation(`/${key}/${id}`);
    };

    return (
        <ProductComponent id={id}>
            {products.map((i, index) => {
                return (
                    <div
                        key={index}
                        className="card"
                        onClick={() => getProductInfo(i.key, i.id)}
                    >
                        <img
                            className="cardImg"
                            src={i.media[0].image}
                            alt=""
                        />
                        <Text
                            textSize="sixteen"
                            children={i.name}
                            textColor={COLORS.black}
                            fontWeight="800"
                            margin="5px 0 0 0"
                        />
                        <div className="priceBox">
                            <Text
                                children={i.series}
                                textColor={COLORS.black}
                                textSize="sixteen"
                                fontWeight="300"
                            />
                        </div>
                        <div className="colors">
                            {i.colors.map((e, index) => {
                                return (
                                    <button
                                        id="colors"
                                        aria-label="Name"
                                        key={index}
                                        className="color"
                                        style={{ backgroundColor: e }}
                                    ></button>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </ProductComponent>
    );
};

export default observer(ProductList);

const ProductComponent = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 12% 80px 12%;
    gap: 30px;

    .card {
        width: 31.5%;
        height: 100%;
        background-color: #f2f2f2;
        padding: 10px;
        border-radius: 3px;
        box-shadow: 0px 5px 10px rgba(108, 107, 107, 0.2);
        cursor: pointer;

        .cardImg {
            width: 100%;
            height: 350px;
            object-fit: cover;
        }

        .priceBox {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .colors {
            display: flex;
            gap: 3px;
            margin: 8px 0 10px 0;
        }

        .color {
            width: 12px;
            height: 12px;
            border: none;
            border-radius: 50%;
        }
    }

    .seeAll {
        display: flex;
        width: 100%;
        justify-content: center;
    }
    @media (min-width: 120px) {
        padding: 50px 2%;
        gap: 15px;
        .card {
            width: 45%;

            .cardImg {
                width: 100%;
                height: 180px;
                object-fit: cover;
            }
        }
    }
    @media (min-width: 576px) {
        .card {
            width: 48%;

            .cardImg {
                width: 100%;
                height: 300px;
                object-fit: cover;
            }
        }
    }
    @media (min-width: 767px) {
        justify-content: center;
        gap: 20px;

        .card {
            width: 31%;

            .cardImg {
                width: 100%;
                height: 280px;
                object-fit: cover;
            }
        }
    }
    @media (min-width: 992px) {
        padding: 40px 10% 80px 10%;
        justify-content: flex-start;
        gap: 25px;
        .card {
            width: 31%;

            .cardImg {
                width: 100%;
                height: 350px;
                object-fit: cover;
            }
        }
    }
    @media (min-width: 1200px) {
        padding: 40px 15% 80px 15%;
        justify-content: flex-start;

        .card {
            width: 31%;

            .cardImg {
                width: 100%;
                height: 350px;
                object-fit: cover;
            }
        }
    }
    @media (min-width: 1400px) {
        .card {
            width: 31.5%;
        }
    }
`;
