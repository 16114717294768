import { observer } from "mobx-react-lite";
import { styled } from "styled-components";
import useRootStore from "../../hooks/useRootStore";
import { COLORS } from "../../utils/colors";
import BasketCard from "../BasketCard/BasketCard";
import Text from "../Text/Text";
import { AiOutlineClose } from "react-icons/ai";
import Input from "../Input/Input";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { message } from "antd";
import MaskInput from "../MaskInput/MaskInput";

const Basket = () => {
    const { visible, hide } = useRootStore().visibleStore;
    const { t } = useTranslation();
    const {
        setBasket,
        basketData,
        addCountProduct,
        setProduct,
        setProductState,
        clearSetProduct,
        reCountProduct,
    } = useRootStore().productStore;

    const data = `Order%0A Name: ${basketData.map(
        (i) => i.name
    )}%0A Model: ${basketData.map((i) =>
        t(i.model)
    )}%0A Count: ${basketData.map((i) => i.count)}%0A Color: ${basketData.map(
        (i) => i.color
    )}
    %0A Price:$ ${basketData.map((i) => i.price)}
    %0A From: ${setProductState.username}
    %0A From: ${setProductState.phoneNumber}`;

    const handleDeleteItem = (e: any) => {
        const updatedItems = basketData.filter((item) => item.id !== e);
        setBasket(updatedItems);
        localStorage.setItem("basket", JSON.stringify(updatedItems));
    };
    if (!basketData.length) {
        setTimeout(() => {
            hide("basket");
        }, 1000);
    }

    const initialValue = 0;
    const sumWithInitial = basketData.reduce(
        //@ts-ignore
        (accumulator, currentValue) =>
            //@ts-ignore
            accumulator + currentValue.count * currentValue.price,
        initialValue
    );

    const sendOrderBot = async () => {
        if (setProductState.phoneNumber.length > 16) {
            await axios({
                method: "post",
                url: `https://api.telegram.org/bot6554712215:AAF0TUcc42AdnIHMp-dOlaiWwEq0CF_T2oA/sendMessage?chat_id=-1002127245312&text=${data}`,
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    message.success(t("order_success"));
                    clearSetProduct();
                    hide("basket");
                    setBasket([]);
                    localStorage.removeItem("basket");
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            message.warning(t("errorNumber"));
            return;
        }
    };

    return (
        <BasketComponent style={{ display: visible.basket ? "block" : "none" }}>
            <AiOutlineClose
                onClick={() => hide("basket")}
                className="close"
                color={COLORS.white}
                size={32}
            />
            <div className="content">
                <Text
                    children="Your order:"
                    textColor={COLORS.black}
                    textSize="twenty"
                />
                <div className="dataBox">
                    {basketData.map((e, index) => {
                        return (
                            <BasketCard
                                key={index}
                                onRemove={() => handleDeleteItem(e.id)}
                                image={e.image}
                                name={e.name}
                                count={e.count as never}
                                price={`$ ${e.price}`}
                                addCount={() => addCountProduct(index)}
                                reCount={() => reCountProduct(index)}
                            />
                        );
                    })}
                </div>
                <div className="totalPrice">
                    <Text
                        textColor={COLORS.black}
                        textSize="sixteen"
                        children={`Total: $ ${sumWithInitial}`}
                    />
                </div>
                <div className="form">
                    <Input
                        placeholder={t("name")}
                        border="1px solid grey"
                        width="100%"
                        onChange={(e) => setProduct("username", e)}
                    />
                    <MaskInput
                        border="1px solid grey"
                        onChange={(e) =>
                            setProduct("phoneNumber", e.target.value)
                        }
                    />
                    <Button
                        onClick={sendOrderBot}
                        children={t("send")}
                        width="100%"
                        padding="15px"
                    />
                </div>
            </div>
        </BasketComponent>
    );
};

export default observer(Basket);

const BasketComponent = styled.div`
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 110;
    animation: animate 0.15s linear;
    user-select: none;
    top: 0;

    @keyframes animate {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }

    .content {
        width: 500px;
        position: absolute;
        z-index: 111;
        top: 50%;
        left: 50%;
        padding: 30px 25px;
        transform: translate(-50%, -50%);
        background-color: #fff;

        .dataBox {
            margin-top: 30px;
            border-top: 1px solid #d8d7d7;
            border-bottom: 1px solid #d8d7d7;
            max-height: 320px;
            overflow-y: auto;
        }
        .totalPrice {
            display: flex;
            justify-content: flex-end;
            padding: 20px 0;
        }
        .form {
            padding: 30px 0 10px 0;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }

    @media (max-width: 776px) {
        .content {
            width: 100%;
            height: 100%;
            padding: 60px 30px;
        }

        .close {
            border-radius: 50%;
            padding: 5px;
            background-color: #000;
            z-index: 112;
        }
    }
`;
