import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { BsGlobe } from "react-icons/bs";
import i18n from "../../translations";
import { useTranslation } from "react-i18next";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { COLORS } from "../../utils/colors";
import useRootStore from "../../hooks/useRootStore";
import { observer } from "mobx-react-lite";
import { IMAGES } from "../../utils/require";

interface Props {
    backColor: string;
}

const Header: React.FC<Props> = ({ backColor }) => {
    const [scroll, isScroll] = useState(false);
    const navigation = useNavigate();
    const { t } = useTranslation();
    const { visible, show, hide } = useRootStore().visibleStore;

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                isScroll(true);
            } else {
                isScroll(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const onHandleCategory = () => {
        hide("mediaMenu");
        navigation("/");
    };

    return (
        <HeaderComponent>
            <div
                className="headerContainer"
                style={{
                    backgroundColor: scroll ? "rgba(0, 0, 0, 0.6)" : backColor,
                }}
            >
                <span onClick={() => navigation("/")}>
                    <img className="logo" src={IMAGES.uncommon} alt="" />
                </span>
                <div className="category">
                    <span onClick={() => navigation("/")}>
                        <a aria-label="Cases content" href="#cases">
                            {t("header.cases")}
                        </a>
                    </span>
                    <span onClick={() => navigation("/")}>
                        <a aria-label="Watchs content" href="#watchs">
                            {t("header.watchs")}
                        </a>
                    </span>
                    <div className="translation">
                        <BsGlobe />
                        <button
                            id="uz"
                            aria-label="Name"
                            style={{
                                backgroundColor:
                                    i18n.language === "uz"
                                        ? "#fff"
                                        : "transparent",
                                color: i18n.language === "uz" ? "#000" : "#fff",
                            }}
                            onClick={() => i18n.changeLanguage("uz")}
                        >
                            {t("header.uz")}
                        </button>
                        <button
                            id="ru"
                            aria-label="Name"
                            style={{
                                backgroundColor:
                                    i18n.language === "ru"
                                        ? "#fff"
                                        : "transparent",
                                color: i18n.language === "ru" ? "#000" : "#fff",
                            }}
                            onClick={() => i18n.changeLanguage("ru")}
                        >
                            {t("header.ru")}
                        </button>
                    </div>
                    <a aria-label="Phone number" href="tel:+998 (91) 011 4855">
                        +998 99 030 46 32
                    </a>
                </div>
                <AiOutlineMenuFold
                    className="menuIcon"
                    size={24}
                    onClick={() => show("mediaMenu")}
                />
                <div
                    className="mediaMenu"
                    style={{ display: visible.mediaMenu ? "block" : "none" }}
                >
                    <div className="mediaHeader">
                        <AiOutlineMenuUnfold
                            size={24}
                            color={COLORS.white}
                            onClick={onHandleCategory}
                        />
                    </div>
                    <div className="menu">
                        <a
                            aria-label="Cases page"
                            href="#cases"
                            onClick={onHandleCategory}
                        >
                            {t("header.cases")}
                        </a>
                        <a
                            aria-label="Watch page"
                            href="#watchs"
                            onClick={onHandleCategory}
                        >
                            {t("header.watchs")}
                        </a>
                        <div className="translation">
                            <BsGlobe />
                            <button
                                id="uz"
                                aria-label="Name"
                                style={{
                                    backgroundColor:
                                        i18n.language === "uz"
                                            ? "#fff"
                                            : "transparent",
                                    color:
                                        i18n.language === "uz"
                                            ? "#000"
                                            : "#fff",
                                }}
                                onClick={() => i18n.changeLanguage("uz")}
                            >
                                {t("header.uz")}
                            </button>
                            <button
                                id="ru"
                                aria-label="Name"
                                style={{
                                    backgroundColor:
                                        i18n.language === "ru"
                                            ? "#fff"
                                            : "transparent",
                                    color:
                                        i18n.language === "ru"
                                            ? "#000"
                                            : "#fff",
                                }}
                                onClick={() => i18n.changeLanguage("ru")}
                            >
                                {t("header.ru")}
                            </button>
                        </div>
                        <a
                            aria-label="Phone number"
                            href="tel:+998 (91) 011 4855"
                        >
                            +998 99 030 46 32
                        </a>
                    </div>
                </div>
            </div>
        </HeaderComponent>
    );
};

export default observer(Header);
const HeaderComponent = styled.div`
    position: fixed;
    z-index: 20;
    top: 0;
    width: 100%;

    .headerContainer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;
        width: 100%;
        color: #fff;
        padding: 0 4%;
        transition: 0.3s ease-in-out;
        background-color: "rgba(0, 0, 0, 0.1)";

        .logo {
            width: 300px;
            height: 80px;
            object-fit: cover;
        }

        .category {
            display: flex;
            align-items: center;
            gap: 20px;
            a {
                text-decoration: none;
                color: #fff;
                font-weight: 600;
                font-size: 16px;
            }
            .translation {
                display: flex;
                align-items: center;
                gap: 3px;

                button {
                    cursor: pointer;
                    border: none;
                    padding: 1px 3px;
                    font-weight: 600;
                }
            }
        }
        .mediaMenu {
            display: none;
        }
        .menuIcon {
            display: none;
        }

        @media (max-width: 768px) {
            .category {
                display: none;
            }
            .logo {
                width: 200px;
            }
            .menuIcon {
                display: block;
            }
            .mediaMenu {
                display: block;
                position: fixed;
                top: 0;
                right: 0;
                z-index: 20;
                background-color: #000;
                width: 70%;
                height: 100%;
                animation: animate 0.2s linear;
                user-select: none;

                .mediaHeader {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    padding: 20px 10%;
                }

                .menu {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    margin-top: 70px;

                    a {
                        text-decoration: none;
                        color: #fff;
                        font-size: 20px;
                    }

                    .translation {
                        display: flex;
                        align-items: center;
                        gap: 3px;

                        button {
                            cursor: pointer;
                            border: none;
                            padding: 1px 3px;
                        }
                    }
                }
            }

            @keyframes animate {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
        }
    }
`;
