import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import Basket from "../../components/Basket/Basket";
import BasketBox from "../../components/BasketBox/BasketBox";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import ProductList from "../../components/ProductList/ProductList";
import Text from "../../components/Text/Text";
import TopContent from "../../components/TopContent/TopContent";
import useRootStore from "../../hooks/useRootStore";
import { COLORS } from "../../utils/colors";
import { CasesData } from "../../utils/db";
import { PantherL1Data } from "../../utils/panther";
import { IMAGES } from "../../utils/require";
import { WolfL1Data } from "../../utils/wolf1";

const CasesView = () => {
    const { t } = useTranslation();
    const { visible, show } = useRootStore().visibleStore;
    const { basketData } = useRootStore().productStore;
    const navigation = useNavigate();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <>
            <CasesContent>
                <Header backColor="rgba(0, 0, 0, 0.2)" />
                <TopContent
                    img1={IMAGES.lion2.pic9}
                    img2={IMAGES.lion1.g}
                    img3={IMAGES.wolf2.d}
                    text={"для iPhone серии"}
                    title="Чехлы"
                />
                <div className="card">
                    <Text
                        textSize="twentyTwo"
                        children={"🌿Современный & Минимальный"}
                        textColor={COLORS.black}
                        textAlign="center"
                        margin="50px 0 0 0"
                        fontWeight="700"
                    />
                    <Text
                        textSize="fifty"
                        fontWeight="700"
                        children={"Чехлы на айфон"}
                        textColor={COLORS.black}
                        textAlign="center"
                    />
                </div>
                <ProductList
                    id="cases"
                    products={CasesData}
                    seeAllBtn={true}
                    seeAll={() => navigation("cases")}
                />
                <div className="card">
                    <Text
                        textSize="twentyTwo"
                        children={"🌿Современный и качественный"}
                        textColor={COLORS.black}
                        textAlign="center"
                        margin="30px 0 0 0"
                        fontWeight="700"
                    />
                    <Text
                        children={"Кожаные чехлы для айфона"}
                        textColor={COLORS.black}
                        textAlign="center"
                        textSize="fifty"
                        fontWeight="700"
                    />
                </div>
                <ProductList
                    id="cases"
                    products={PantherL1Data}
                    seeAllBtn={true}
                    seeAll={() => navigation("cases")}
                />
                <div className="card">
                    <Text
                        textSize="twentyTwo"
                        children={"🌿Современный и качественный"}
                        textColor={COLORS.black}
                        textAlign="center"
                        margin="50px 0 0 0"
                        fontWeight="700"
                    />
                    <Text
                        children={"Кожаные чехлы для айфона"}
                        textColor={COLORS.black}
                        textAlign="center"
                        textSize="fifty"
                        fontWeight="700"
                    />
                </div>
                <ProductList
                    id="cases"
                    products={WolfL1Data}
                    seeAllBtn={true}
                    seeAll={() => navigation("cases")}
                />
                <Footer />
                <Basket />
            </CasesContent>
            {basketData.length && !visible.basket ? (
                <BasketBox
                    count={basketData.length}
                    openBasket={() => show("basket")}
                />
            ) : null}
        </>
    );
};

export default observer(CasesView);

const CasesContent = styled.div`
    position: relative;
    height: 100%;
`;
