import React, { useState } from "react";
import { styled } from "styled-components";
import Text from "../Text/Text";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { FaTelegram } from "react-icons/fa";
import { COLORS } from "../../utils/colors";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import MaskInput from "../MaskInput/MaskInput";
import { message } from "antd";
import axios from "axios";
import { observer } from "mobx-react-lite";

const Contact = () => {
    const { t } = useTranslation();
    const [number, setNumber] = useState("");

    const onChangeNumber = (e: any) => {
        setNumber(e);
    };

    const contactBot = async () => {
        if (number) {
            await axios({
                method: "post",
                url: `https://api.telegram.org/bot6554712215:AAF0TUcc42AdnIHMp-dOlaiWwEq0CF_T2oA/sendMessage?chat_id=-1002127245312&text=${number}`,
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    message.success(t("contact_success"));
                    setNumber("");
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            message.warning(t("errorNumber"));
            return;
        }
    };

    return (
        <ContactComponent>
            <div className="icons">
                <a
                    aria-label="Instagram profile"
                    href="https://www.instagram.com/uncommontech.uz"
                    target={"_blank"}
                >
                    <BiLogoInstagramAlt size={30} className="icon" />
                </a>
                <a
                    aria-label="Telegram channel"
                    href="https://t.me/uncommontechuz"
                    target={"_blank"}
                >
                    <FaTelegram size={30} />
                </a>
            </div>
            <Text
                textSize="twentyEight"
                fontWeight="400"
                textAlign="center"
                textColor={COLORS.black}
                children={t("follow")}
            />
            <div className="form">
                <MaskInput
                    onChange={(e) => onChangeNumber(e.target.value)}
                    borderRadius="5px"
                    value={number}
                />
                <Button
                    children={t("send")}
                    padding="15px"
                    onClick={contactBot}
                    backColor={COLORS.black}
                    textColor={COLORS.white}
                />
            </div>
        </ContactComponent>
    );
};

export default observer(Contact);

const ContactComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 600px;
    padding: 100px 20%;
    background-color: #f2f2f2;

    .icons {
        display: flex;
        gap: 15px;

        .icon {
            color: #f2f2f2;
            background-color: #000;
            border-radius: 50%;
            padding: 3px;
        }
    }

    .form {
        display: flex;
        gap: 20px;
    }
    @media (max-width: 576px) {
        padding: 80px 10%;
        .form {
            flex-direction: column;
        }
    }
`;
