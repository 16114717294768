import React from "react";
import { styled } from "styled-components";

interface Props {
    children?: string;
    backColor?: string;
    textSize?: string;
    textColor?: string;
    margin?: string;
    onClick?: () => void;
    width?: string;
    padding?: string;
    borderRadius?: string;
}

const Button: React.FC<Props> = ({
    backColor,
    children,
    onClick,
    textColor,
    textSize,
    margin,
    width,
    padding,
    borderRadius,
}) => {
    return (
        <ButtonComponent
            id="button-component"
            aria-label="Name"
            onClick={onClick}
            style={{
                backgroundColor: backColor,
                color: textColor,
                fontSize: textSize,
                margin: margin,
                width: width,
                padding: padding,
                borderRadius: borderRadius,
            }}
        >
            {children}
        </ButtonComponent>
    );
};

export default Button;

const ButtonComponent = styled.button`
    border: none;
    background-color: #f2f2f2;
    color: #000;
    padding: 10px 25px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    font-weight: 700;

    :hover {
        background-color: #000;
        color: #f2f2f2;
    }
    @media (max-width: 576px) {
        padding: 6px 20px;
    }
`;
