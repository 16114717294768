import React from "react";
import { styled } from "styled-components";
import Text from "../Text/Text";

interface Props {
    image?: string;
    height?: string;
    backAttachment?: any;
    downBtn?: boolean;
    title?: string;
    text?: string;
    backPosition?: any;
    icon?: any;
    backSize?: any;
    backRgb?: boolean;
}

const MainContent: React.FC<Props> = ({
    backAttachment,
    downBtn,
    height,
    image,
    title,
    backPosition,
    backSize,
    text,
    icon,
    backRgb,
}) => {
    const DownHandle = () => {
        window.scrollTo({ top: 800, behavior: "smooth" });
    };

    return (
        <MainComponent
            property={image}
            style={{
                height: height,
                backgroundAttachment: backAttachment,
                backgroundPosition: backPosition,
                backgroundSize: backSize,
            }}
        >
            <div className="content">
                <Text children={title} textSize="fifty" fontWeight="800" />
                <Text
                    children={text}
                    textSize="twenty"
                    margin="10px 0 0 0"
                    fontWeight="800"
                />
            </div>
            {downBtn ? (
                <button id="down" aria-label="Name" onClick={DownHandle}>
                    {icon}
                </button>
            ) : null}
            {backRgb ? <div className="above"></div> : null}
        </MainComponent>
    );
};

export default MainContent;

const MainComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url(${(props) => props.property});
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    height: 850px;
    width: 100%;
    padding: 0 5%;

    .content {
        position: relative;
        z-index: 2;
        text-align: center;
    }
    button {
        position: absolute;
        border: none;
        border-radius: 50%;
        padding: 10px;
        background-color: transparent;
        bottom: 150px;
        cursor: pointer;
        z-index: 2;
    }

    .above {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.3);
    }

    @media (max-width: 776px) {
        background-attachment: scroll;
    }
`;
