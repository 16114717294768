import { observer } from "mobx-react-lite";
import React from "react";
import { styled } from "styled-components";
import useRootStore from "../../hooks/useRootStore";
import { COLORS } from "../../utils/colors";
import Text from "../Text/Text";
interface Props {
    name?: string;
    type?: string;
    description?: string;
}

const ProductInfoCard: React.FC<Props> = ({ description, name, type }) => {
    return (
        <ProductInfoComponent>
            <Text
                children={name}
                textSize="thirtySix"
                textColor={COLORS.black}
            />
            <Text
                children={type}
                textSize="eighteen"
                margin="5px 0 0 0"
                textColor={COLORS.black}
            />
            <details>
                <summary>{description?.slice(0, 130)}</summary>
                <Text
                    children={description?.slice(130)}
                    textSize="fourteen"
                    fontWeight="200"
                    lineHeight="20px"
                    textColor={COLORS.black}
                />
            </details>
        </ProductInfoComponent>
    );
};

export default observer(ProductInfoCard);

const ProductInfoComponent = styled.section`
    position: relative;
    z-index: 11;
    width: 100%;
    border-radius: 10px;
    padding: 20px 0;

    details {
        margin-top: 15px;
        font-size: 14px;
    }

    @media (max-width: 576px) {
        padding: 10px 0;
    }
    /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); */
    /* background-color: red; */
`;
