import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { IMAGES } from "../../utils/require";
import Text from "../Text/Text";

const CategoryContent = () => {
    const navigation = useNavigate();

    return (
        <CategoryContainer>
            <div className="category_card" onClick={() => navigation("cases")}>
                <img src={IMAGES.category1} alt="" />
                <div className="category_title">
                    <Text children={"Чехлы"} textSize="thirtySix" />
                </div>
            </div>
            <div className="category_card" onClick={() => navigation("watchs")}>
                <img src={IMAGES.category2} alt="" />
                <div className="category_title">
                    <Text children={"Часы"} textSize="thirtySix" />
                </div>
            </div>
        </CategoryContainer>
    );
};

export default CategoryContent;

const CategoryContainer = styled.div`
    display: flex;
    gap: 20px;
    padding: 0 1%;

    .category_card {
        position: relative;
        width: 49%;
        height: 500px;
        border-radius: 7px;
        overflow: hidden;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .category_title {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }

    @media (max-width: 767px) {
        flex-direction: column;

        .category_card {
            width: 100%;
            height: 400px;
        }
    }
`;
