export type ProductMediaType = {
    id: number;
    image: string;
    video: string;
    color: string;
    type: string;
};

export const ProductMediaInitial = {
    id: 0,
    image: "",
    video: "",
    color: "",
    type: "",
};

export type ProductDataType = {
    id: number;
    key: string;
    type: string;
    name: string;
    description: string;
    price: number | string;
    discount: string;
    discountP: string;
    info: string;
    colors: string[];
    models: string[];
    media: ProductMediaType[];
    mediaWithInfo: string[];
    series: string;
};

export const ProductDataInitial = {
    id: 0,
    key: "",
    type: "",
    name: "",
    description: "",
    price: 0 || "",
    discount: "",
    discountP: "",
    info: "",
    colors: [""],
    models: [""],
    media: [ProductMediaInitial],
    mediaWithInfo: [""],
    series: "",
};

export type SetProductType = {
    id: number;
    image: string;
    name: string;
    count: number;
    price: number | string;
    totalPrice: string;
    color: string;
    model: string;
    type: string;
    username: string;
    phoneNumber: string;
};

export const SetProductInitial = {
    id: 0,
    image: "",
    name: "",
    count: 0,
    price: 0 || "",
    totalPrice: "",
    color: "",
    model: "",
    type: "",
    username: "",
    phoneNumber: "",
};
