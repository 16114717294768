import React from "react";
import { styled } from "styled-components";
import { BiShoppingBag } from "react-icons/bi";
import { observer } from "mobx-react-lite";
import Text from "../Text/Text";
interface Props {
    count: any;
    openBasket?: () => void;
}

const BasketBox: React.FC<Props> = ({ count, openBasket }) => {
    return (
        <BasketBoxComponent onClick={openBasket}>
            <BiShoppingBag size={26} />
            <div className="count">
                <Text children={count} textSize={"fourteen"} />
            </div>
        </BasketBoxComponent>
    );
};

export default observer(BasketBox);

const BasketBoxComponent = styled.div`
    position: fixed;
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    background-color: #fff;
    border-radius: 50%;
    right: 70px;
    top: 100px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;

    .count {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #000;
        padding: 5px;
        border-radius: 50%;
        min-width: 25px;
    }

    @media (max-width: 776px) {
        right: 30px;
        top: 90px;
    }
`;
