import { makeAutoObservable } from "mobx";
import { createContext } from "react";
import ProductStore from "./ProductStore";
import VisibilityStore from "./VisibilityStore";

export class AppRootStore {
    visibleStore = new VisibilityStore();
    productStore = new ProductStore();

    constructor() {
        makeAutoObservable(this);
    }
}

const rootStore = new AppRootStore();
export default createContext(rootStore);
