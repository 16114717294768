export const COLORS = {
    white: "white",
    black: "black",
    darkBlue: "darkBlue",
    darkBrown: "darkgoldenrod",
    brown: "brown",
    grey: "grey",
    yellow: "yellow",
    silver: "silver",
    red: "red",
    purple: "purple",
    green: "green",
    darkGreen: "darkgreen",
    blue: "blue",
    dimGray: "dimgray",
    saddleBrown: "saddlebrown",
};
