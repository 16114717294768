import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import Basket from "../../components/Basket/Basket";
import BasketBox from "../../components/BasketBox/BasketBox";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import ProductList from "../../components/ProductList/ProductList";
import Text from "../../components/Text/Text";
import TopContent from "../../components/TopContent/TopContent";
import useRootStore from "../../hooks/useRootStore";
import { COLORS } from "../../utils/colors";
import { WatchsData } from "../../utils/db";
import { IMAGES } from "../../utils/require";

const WatchsView = () => {
    const { t } = useTranslation();
    const { visible, show } = useRootStore().visibleStore;
    const { basketData } = useRootStore().productStore;
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <>
            <CasesContent>
                <Header backColor="rgba(0, 0, 0, 0.1)" />
                <TopContent
                    img1={IMAGES.watch1.f}
                    img2={IMAGES.watch3.j}
                    img3={IMAGES.watch2.b}
                    text={"Необичный и современный"}
                    title="Часы"
                />
                <Text
                    textSize="twentyTwo"
                    children={"🌿Современный & Минимальный"}
                    textColor={COLORS.black}
                    textAlign="center"
                    margin="50px 0 0 0"
                    fontWeight="700"
                />
                <Text
                    textSize="fifty"
                    fontWeight="700"
                    children={"Магнитные Часы"}
                    textColor={COLORS.black}
                    textAlign="center"
                />
                <ProductList products={WatchsData} seeAllBtn={false} />
                <Footer />
                <Basket />
            </CasesContent>
            {basketData.length && !visible.basket ? (
                <BasketBox
                    count={basketData.length}
                    openBasket={() => show("basket")}
                />
            ) : null}
        </>
    );
};

export default observer(WatchsView);

const CasesContent = styled.div`
    position: relative;
    height: 100%;
`;
