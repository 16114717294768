import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import useRootStore from "../../hooks/useRootStore";
import { COLORS } from "../../utils/colors";
import Text from "../Text/Text";

interface Props {
    price?: number | string;
    models?: string[];
    colors?: string[];
    chooseColor: (e: any) => void;
    chooseModel: (e: any) => void;
    discount?: string;
    discountP?: string;
}

const ProductModels: React.FC<Props> = ({
    chooseColor,
    chooseModel,
    colors,
    models,
    price,
    discount,
    discountP,
}) => {
    const { setProductState } = useRootStore().productStore;
    const { t } = useTranslation();

    return (
        <ProductModelsCard>
            <div className="priceBox">
                <Text
                    children={price}
                    textColor={COLORS.black}
                    textSize="twenty"
                />
                {discount ? (
                    <Text
                        children={discount}
                        textColor={COLORS.grey}
                        textSize="sixteen"
                        textDec="line-through"
                    />
                ) : null}
                {discountP ? (
                    <Text
                        children={discountP}
                        textColor={COLORS.red}
                        textSize="sixteen"
                        // textDec="line-through"
                    />
                ) : null}
            </div>
            <Text
                children={t("model")}
                textColor={COLORS.black}
                margin="20px 0 10px 0"
                textSize="twenty"
            />
            <div className="modelBox">
                {models?.map((e, index) => {
                    return (
                        <button
                            id="product-model"
                            aria-label="Name"
                            className="model"
                            key={index}
                            onClick={() => chooseModel(e)}
                            style={{
                                border:
                                    setProductState.model == e
                                        ? "2px solid #3BFD5B"
                                        : "2px solid grey",
                            }}
                        >
                            {t(e)}
                        </button>
                    );
                })}
            </div>
            <Text
                children={t("color")}
                textColor={COLORS.black}
                margin="20px 0 10px 0"
                textSize="twenty"
            />
            <div className="colorBox">
                {colors?.map((e, index) => {
                    return (
                        <span
                            className="colorBtn"
                            key={index}
                            onClick={() => chooseColor(e)}
                            style={{
                                border:
                                    setProductState.color == e
                                        ? " 2px solid #3BFD5B"
                                        : "1px solid grey",
                            }}
                        >
                            <button
                                id="product-color"
                                aria-label="Name"
                                className="color"
                                style={{ backgroundColor: e }}
                            ></button>
                        </span>
                    );
                })}
            </div>
        </ProductModelsCard>
    );
};

export default observer(ProductModels);

const ProductModelsCard = styled.div`
    position: relative;
    z-index: 11;
    width: 100%;
    border-radius: 10px;
    padding: 10px 0;

    .priceBox {
        margin-top: 5px;
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .modelBox {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .model {
            padding: 3px 5px;
            color: #000;
            background-color: transparent;
            border: 1px solid grey;
        }
    }

    .colorBox {
        display: flex;
        gap: 10px;

        .colorBtn {
            width: 25px;
            height: 25px;
            padding: 2px;
            background-color: transparent;
            border: 1px solid grey;

            .color {
                width: 100%;
                height: 100%;
                border: none;
            }
        }
    }
`;
