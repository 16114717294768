import React from "react";
import { styled } from "styled-components";
import Text from "../Text/Text";
interface Props {
    imgUrl1?: string;
    imgUrl2?: string;
    title?: string;
    text?: string;
    downBtn?: boolean;
    icon?: any;
    backRgb?: boolean;
}

const ProductContent: React.FC<Props> = ({
    imgUrl1,
    imgUrl2,
    text,
    title,
    downBtn,
    icon,
    backRgb,
}) => {
    const DownHandle = () => {
        window.scrollTo({ top: 800, behavior: "smooth" });
    };

    return (
        <ProductContentContainer>
            <img src={imgUrl1} alt="" />
            <img src={imgUrl2} alt="" />

            <div className="info_above">
                <Text
                    textAlign={"center"}
                    textSize="eighteen"
                    children={text}
                />
                <Text textAlign={"center"} textSize="sixty" children={title} />
            </div>
            {downBtn ? (
                <button id="down" aria-label="Name" onClick={DownHandle}>
                    {icon}
                </button>
            ) : null}
            {backRgb ? <div className="above_rgba"></div> : null}
        </ProductContentContainer>
    );
};

export default ProductContent;

const ProductContentContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    position: relative;

    button {
        position: absolute;
        border: none;
        border-radius: 50%;
        padding: 10px;
        background-color: transparent;
        bottom: 150px;
        cursor: pointer;
        z-index: 2;
        left: 50%;
        transform: translate(-50%, 0);
        animation: animate 2s ease-in-out;
    }
    @keyframes animate{
        0%{
            bottom: 150px;
        }
        50%{
            margin-top: 140px;
        }
        100%{
            bottom: 150px;
        }
    }



    img {
        width: 50%;
        height: 100%;
        object-fit: cover;
    }
    .info_above {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        top: 45%;
        left: 50%:
        transform: translate(-50%, -50%);
        z-index: 2;
        padding: 0 2%;
    }
    .above_rgba{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0,0,0, 0.5);
        z-index: 1;
    }

    @media(max-width: 767px){
        flex-direction: column;
        height: 80vh;

        .above_rgba{
      
        height: 80vh;
       
    }
        img{
            width:100%;
            height: 50%;
        }
    }
`;
