import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import { COLORS } from "../../utils/colors";
import { DeliveryVariantsData } from "../../utils/db";
import Text from "../Text/Text";

const Delivery = () => {
    const { t } = useTranslation();
    return (
        <DeliveryComponent>
            <Text
                children={t("delivery.delivery")}
                textColor={COLORS.black}
                textSize="thirtySix"
                textAlign={"center"}
            />
            <Text
                children={t("delivery.deliveryVariants")}
                textColor={COLORS.black}
                textSize="twentyEight"
                margin="20px 0 0 0"
            />
            <div className="variants">
                {DeliveryVariantsData.map((i, index) => {
                    return (
                        <div className="card" key={index}>
                            <img src={i.icon} alt="" />
                            <Text
                                children={t(i.type)}
                                textColor={COLORS.black}
                                textSize="twentyTwo"
                                margin="50px 0 0 0"
                            />
                            <Text
                                children={t(i.price)}
                                textSize="eighteen"
                                textColor={COLORS.black}
                                margin="25px 0 0 0"
                            />
                            <Text
                                children={t(i.info)}
                                textColor={COLORS.black}
                                textSize="fourteen"
                                fontWeight="200"
                                margin="10px 0 0 0"
                            />
                        </div>
                    );
                })}
            </div>
        </DeliveryComponent>
    );
};

export default Delivery;

const DeliveryComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 10%;
    /* background-color: #fcead6; */

    .variants {
        display: flex;
        align-items: flex-start;
        gap: 30px;
        flex-wrap: wrap;

        .card {
            text-align: center;
            width: 31.5%;
            padding: 10px 10px;
            margin-top: 50px;
            img {
                width: 100px;
                border-radius: 50%;
                background-color: #f2f2f2;
            }
        }
        @media (min-width: 120px) {
            justify-content: center;

            .card {
                width: 100%;
            }
        }
        @media (min-width: 576px) {
            justify-content: center;

            .card {
                width: 80%;
            }
        }
        @media (min-width: 992px) {
            justify-content: flex-start;

            .card {
                width: 47%;
            }
        }
        @media (min-width: 1200px) {
            .card {
                width: 31%;
            }
        }
        @media (min-width: 1400px) {
            .card {
                width: 31.5%;
            }
        }
    }
`;
