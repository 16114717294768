import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { styled } from "styled-components";
import useRootStore from "../../hooks/useRootStore";
import { COLORS } from "../../utils/colors";
import Text from "../Text/Text";

const MediaCard = () => {
    const { productInfo, mediaCurrent, setMediaCurrent, productMedia } =
        useRootStore().productStore;

    return (
        <MediaCardComponent>
            <div className="images">
                {productMedia.map((e, index) => {
                    return (
                        <img
                            style={{
                                opacity:
                                    e?.id === mediaCurrent?.id ? "1" : "0.4",
                            }}
                            onClick={() => setMediaCurrent(e.id)}
                            key={index}
                            src={e?.image}
                            alt=""
                        />
                    );
                })}
            </div>
            <img className="currentImg" src={mediaCurrent?.image} alt="" />
            {!mediaCurrent?.image ? (
                <div className="response">
                    <Text
                        textSize="twenty"
                        textColor={COLORS.black}
                        children={"Bunday rangdagi chexol xozircha mavjud emas"}
                    />
                </div>
            ) : null}
        </MediaCardComponent>
    );
};

export default observer(MediaCard);

const MediaCardComponent = styled.div`
    display: flex;
    gap: 10px;

    .currentImg {
        display: flex;
        max-height: 650px;
        height: 650px;
        width: 82%;
        object-fit: contain;
        align-self: flex-start;
    }

    .response {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 600px;
        width: 100%;
    }
    .images ::-webkit-scrollbar {
        display: none;
    }
    .images {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 15%;
        height: 100%;
        padding: 0 0 10px 0;
        overflow: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;

        img {
            min-width: 100px;
            max-width: 100px;
            object-fit: contain;
        }
    }

    @media (min-width: 120px) {
        flex-direction: column-reverse;

        .currentImg {
            width: 100%;
            height: 300px;
        }

        .images {
            flex-direction: row;
            width: 100%;
            height: 150px;
            align-self: flex-start;
            overflow-y: hidden;
        }
    }
    @media (min-width: 576px) {
        .currentImg {
            height: 400px;
        }
    }

    @media (min-width: 992px) {
        .currentImg {
            width: 95%;
            height: 600px;
        }
        .images {
            width: 95%;
        }
    }
    @media (min-width: 1200px) {
        flex-direction: row;
        height: 650px;

        .currentImg {
            width: 82%;
            max-height: 650px;
            height: 650px;
        }
        .images {
            flex-direction: column;
            width: 15%;
            height: 100%;
            overflow-y: auto;
        }
    }
`;
